import * as React from 'react';
import * as styles from './index.module.css';
import Nav from './main/nav';
import Home from './main/home';
import { Showcase } from './main/showcase';
import { Background } from './main/background';
import Contact from './main/contact';

function Index() {
  const homeElement = React.useRef(null);
  const backgroundElement = React.useRef(null);
  const showcaseElement = React.useRef(null);
  const contactElement = React.useRef(null);

  return (
    <>
      <Nav refs={[homeElement, showcaseElement, backgroundElement, contactElement]} />
      <div className={styles.siteContainer}>
        <Home refElement={homeElement} />
        <Showcase refElement={showcaseElement} />
        <Background refElement={backgroundElement} />
        <Contact refElement={contactElement} />
      </div>
    </>
  );
}

export default Index;
